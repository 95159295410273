<template>
  <b-container class="min-vh-100 d-flex flex-column">
    <b-row>
      <b-col cols="1"/>
      <b-col>
        <h1 style="margin-top: 4.25rem;">Login</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <h4 style="margin-top: 2rem; margin-bottom: 1rem;">Bitte melde dich an</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1"/>
        <b-col >
          <b-input style="margin-bottom: 0.5rem" type="text" placeholder="E-Mail" :state="mail.length === 0 ? null : mailValid" v-model="mail"/>
        </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
        <b-col>
          <b-input type="password" placeholder="Passwort" :state="pwd.length === 0 ? null : pwdValid" v-model="pwd"/>
        </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row class="flex-grow-1">
      <b-col cols="1"/>
        <b-col class="justify-content-center align-self-center">
          <b-button  variant="primary" style="width: 100%; margin-top: 2rem" @click="login">Anmelden</b-button>
        </b-col>
      <b-col cols="1"/>
    </b-row>
  </b-container>
</template>

<script>
// import RoundedButton from '../components/RoundedButton';

export default {
  name: 'Start',
  data() {
    return {
      mail: '',
      pwd: '',
    };
  },
  mounted() {
    // redirects if user is already logged in
    if (this.$store.state.userProfile.accepted !== undefined) this.$router.push("/tos");
  },
  methods: {
    login() {
      console.log('logging in…')
      // authotizes user from the backend
      this.axios.post('auth/login', {
        mail: this.mail,
        pwd: this.pwd
      })
        .then(( res ) => {
          localStorage.setItem('token', res.data.token);
          this.$store.state.loggedIn = true;
          localStorage.setItem('preName', res.data.preName);
          localStorage.setItem('lastName', res.data.lastName);
          // gets userprofile after logged in successfully
          this.axios.get('user/getUserProfile', {
            headers: {
              token: localStorage.getItem('token'),
            }
          })
            .then(async () => {
              // redirects after login
                this.getUserProfile().then(()=>this.$router.push("/tos"));
              })
              .catch((err) => {
                this.toast('Es ist ein Fehler aufgetreten', 'Fehler beim Laden des Nutzerprofils', 'danger')
                console.error(err);
              });
        })
        .catch((/*err*/) => {
          this.toast('Es ist ein Fehler aufgetreten', 'Ungültige Login-Daten', 'danger')
        });
    },
  },
  computed: {
    mailValid() {
      // checks if the given mail adress is valid using regex
      const regex = RegExp(/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/);
      return regex.test(this.mail);
    },
    pwdValid() {
      return this.pwd.length > 6;
    }
  },
  components: {
  }
}
</script>

<style
    RoundedButton>
</style>